import { IWheelDepartment } from './departement/department.model';
import { ITableReservationModel } from './location/table-reservation.model';

export interface IWheelSetupModel {
  success: boolean;
  result: IWheelSetupResultModel;
  error: string;
}

export interface IWheelSetupResultModel {
  welcomeMessage: string;
  termsAndConditions: string;
  wheelPostCodeServiceType: number;
  wheelLocations: Array<IWheelSetupLocation>;
  isDisplayHomePage: boolean;
  countryCode: string;
  currency: string;
  logo: string;
  pageTitle: string;
  justDisplay: boolean;
  noProductImage: string;
  addOns: string;
  addOnsModel: IWheelSetupAddon;
}

export interface IWheelSetupLocation {
  id: number;
  name: string;
  enabled: boolean;
  taxInclusive: boolean;
  outsideDeliveryZone: boolean;
  locationId: number;
  addressLatitude: number;
  addressLongitude: number;
  displayMenu: boolean;
  distanceRestrictInMeters: number;
  wheelDepartments: Array<IWheelDepartment>;
}

export interface IWheelSetupAddon {
  BaseColor: string;
  AlternateColor: string;
  AnimationImage: string;
  FacebookLink: string;
  InstagramLink: string;
  TwitterLink: string;
  NoLogin: boolean;
  ForceLogin: boolean;
  SkipTimeSlot: boolean;
  HideBranding: boolean;
  BackgroundColor: string;
  ButtonTextColor: string;
  HomePageBackgroundColor: string;
  MenuCategoryScrolling: boolean;
  MultiLocation: boolean;
  FreeTagging: boolean;
  MenuWithoutImage: boolean;
  KitchenStatusMessage: string;
  KitchenNotAcceptMessage: string;
  AddMembershipButtonText: string;
  SearchMembershipButtonText: string;
  ServiceSetup: boolean;
  ShowCurrency: boolean;
  HideSignup: boolean;
  HideVoucher:boolean;
  HideMenuFilters: boolean;
  HideHomePageLogo: boolean;
  IsWokHey: boolean;
  DisplayTicketNotes: boolean;
  SignInMessage: string;
  DefaultGridView: boolean;
  WheelCustomScript: string;
  ShowOrderTagAlias: boolean;
  OutOfOperationHoursMessage: string;
  OrderKitchenSyncMessage: string;
  AutoCloseItemMenu: boolean;
  ErrorMessagePopup: boolean;
  DisableAutoSelectItems: boolean;
  ValidatePhoneNumber: boolean;
  LegalName: string;
  IsCosford: boolean;
  NoCheckoutSkip: boolean;
  KeyInMembershipRequired: boolean;
  DepartmentCustomizationText: string;
  KeyInMembershipLabel: string;
  KeyInMembershipPlaceholder: string;
  DisplayContactInDineIn: boolean;
}

export interface IWheelCurrentSession {
  name?: any;
  phoneNumber?: any;
  emailAddress?: any;
  sessionId: string;
  table: number;
  wheelLocation: number;
  tableName: string;
  invoiceNumber: string;
  reservation: ITableReservationModel;
  customerGuid: any;
}
